<template>
    <v-container fluid class="courseCategory_show_wrapper">
        
        <div v-if="editedCourseCategory">
            <TopCard 
                :text="'Course Category ' + editedCourseCategory.name"
            />

            <br>
            
            <v-tabs vertical class="courseCategory_show_card">
                <template v-for="(tab, index) in tabs">
                    <v-tab :key="index">
                        {{tab.text}}
                    </v-tab>
                </template>

                <template v-for="(tab, index) in tabs">
                    <v-tab-item :key="index">

                        <div class="pl-5">
                            <component :courseCategory="editedCourseCategory" :is="tab.component" />
                        </div>
                    </v-tab-item>
                </template>
            </v-tabs>
        </div>

        <FormLoader v-else />

    </v-container>
</template>

<script>
import FormLoader from '../../../components/Loaders/FormLoader.vue'
import TopCard from '../../../components/Cards/TopCard.vue'
import CourseCategoryDetails from '../../../components/CourseCategory/CourseCategoryDetails.vue'

export default {
    components: {
        FormLoader,
        TopCard,
        CourseCategoryDetails,
    },

    data() {
        return {
            tabs: [
                { 
                    text: 'Details', 
                    component: 'CourseCategoryDetails', 
                },
            ],
            editedCourseCategory: null,
        }
    },

    created() {
        return this.getCourseCategory();
    },

    watch: {
        courseCategories() {
            this.getCourseCategory();
        }
    },

    computed: {
        courseCategories() {
            return this.$store.getters['CourseCategoryState/courseCategories'];
        }
    },

    methods: {
        async getCourseCategory() {
            this.editedCourseCategory = await this.$store.dispatch('CourseCategoryState/getCourseCategory', this.$route.params.courseCategoryID);
        },

        submit() {
            this.errors = null;
            if(!this.$refs.form.validate()) {
                return;
            }

            this.loading = true;
            
            this.$store.dispatch('CourseCategoryState/createCourseCategory', this.form)
                .then(res => {
                    this.$store.dispatch('MessageState/addMessage', {
                        message: `CourseCategory ${this.form.first_name} ${this.form.last_name} created successfully`
                    });
                    this.$router.push('/courseCategories')
                })
                .catch(err => {
                    this.errors = err.errors;
                    this.$store.dispatch('MessageState/addMessage', {
                        message: 'Failed to create the courseCategory',
                        type: 'error',
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

    .courseCategory_show_card {
        min-height: 70vh;
    }

    ::v-deep .v-window {
        background-color: #eeeef1 !important;
    }

</style>